import "./Terms.css";

function Terms() {
  return (
    <div className="terms-container">
      <h3>Terms of Use</h3>
      <p>
        I. These terms of use (the <b>“Terms of Use”</b>) govern Parties’ use of
        our website www.staples- plus.swiggy.com (the <b>“Website”</b> or{" "}
        <b>“Platform”</b>). Please read these Terms of Use carefully before
        using the services. If a Party does not agree to these Terms of Use,
        such Party may not use the services on the Website. By using the
        Website, a Party shall be contracting with Swiggy and such Party
        signifies its acceptance to the Terms of Use and other Swiggy policies
        (including but not limited to the Cancellation &amp; Refund Policy,
        Privacy Policy and Take Down Policy) as posted on the Platform from time
        to time, which takes effect on the date on which a Party uses the
        Services, and creates a legally binding arrangement to abide by the
        same.
      </p>
      <p>
        II. The Platform is operated and owned by Bundl Technologies Private
        Limited, a company incorporated under the Companies Act, 1956 and having
        its registered office at No. 17/9B, 3 rd Floor (internally designated as
        4th Floor), Maruthi Chambers, Rupena Agrahara, Hosur Road, Bangalore –
        560 068. For the purpose of these Terms of Use, wherever the context so
        requires, “Party” or “Parties” or “User” shall mean any natural or legal
        person who has agreed to become a buyer or merchant on the Platform by
        providing Registration Data while registering on the Platform as a
        registered user using any computer systems. The terms “Swiggy”, “we”,
        “us” or “our” shall mean Bundl Technologies Private Limited.
      </p>
      <p>
        Swiggy offers on the Platform, Business to Business (B2B) services
        including but not limited to enabling online discovery and purchase of
        Raw Material (fruits, vegetables, meat, fish, dairy, frozen food, food
        grain, pulses, loose commodities) for the Users (
        <b>“Platform Services”</b> or <b>“Services”</b>). The buyers (
        <b>“Buyer/s”</b>) can choose and place orders (<b>“Order/s”</b>) from
        variety of products and services listed and offered on the Platform by
        Swiggy. Buyer/s and Swiggy shall hereinafter individually be known as{" "}
        <b>“Party”</b> and collectively as
        <b>“Parties”.</b>
      </p>
      <p>
        <h4>III. AMENDMENTS</h4>
      </p>
      <p>
        These Terms of Use are subject to modifications at any time. We reserve
        the right to modify or change these Terms of Use and other Swiggy
        policies at any time by posting changes on the Platform, and a Party
        shall be liable to update itself of such changes, if any, by accessing
        the changes on the Platform. A Party shall, at all times, be responsible
        for regularly reviewing the Terms of Use and the other Swiggy policies
        and note the changes made on the Platform. User’s continued usage of the
        services after any change is posted constitutes an acceptance of the
        amended Terms of Use and other Swiggy policies. As long as a Party
        complies with these Terms of Use, Swiggy grants such Party a personal,
        non-exclusive, non-transferable, limited privilege to access, enter, and
        use the Platform. By accepting these Terms of Use, Parties also accept
        and agree to be bound by the other terms and conditions and Swiggy
        policies as may be posted on the Platform from time to time.
      </p>
      <p>
        <h4>IV. Use of Platform and Services</h4>
      </p>
      <p>
        1. Swiggy does not make any representation or warranty as to the
        item-specifics (such as legal title, creditworthiness, identity, etc.).
        <br />
        2. Swiggy is providing a platform for sale and it is agreed that the
        contract for sale of any of the products shall be a strictly bipartite
        contract between Swiggy and the Buyer. In case of complaints from the
        Buyer pertaining to quality or any other such issues, same shall be
        addressed to Swiggy subject to the terms of the manufacturer and/or
        supplier.
        <br />
        3. Please note that there could be risks in dealing with underage
        persons or people acting under false pretence.
        <br />
        4. Swiggy - Use of the Website Parties agree, undertake and confirm that
        the use of Platform shall be strictly governed by the following binding
        principles:
        <br />
        i. Parties shall not host, display, upload, download, modify, publish,
        transmit, update or share any information which:
        <br />
        a. belongs to another person and which a Party does not have any right
        to;
        <br />
        b. is grossly harmful, harassing, blasphemous, defamatory, obscene,
        pornographic, paedophilic, libellous, slanderous, criminally inciting or
        invasive of another’s privacy, hateful, or racially, ethnically
        objectionable, disparaging, relating or encouraging money laundering or
        gambling, or otherwise unlawful in any manner whatsoever; or unlawfully
        threatening or unlawfully harassing including but not limited to
        “indecent representation of women” within the meaning of the Indecent
        Representation of Women (Prohibition) Act, 1986;
        <br />
        c. is misleading or misrepresentative or false or inaccurate in any way;
        <br />
        d. is patently offensive to the online community, such as sexually
        explicit content, or content that promotes obscenity, paedophilia,
        racism, bigotry, hatred or physical harm of any kind against any group
        or individual;
        <br />
        e. harasses or advocates harassment of another person;
        <br />
        f. involves the transmission of “junk mail”, “chain letters”, or
        unsolicited mass mailing or “spamming”;
        <br />
        g. promotes illegal activities or conduct that is abusive, threatening,
        obscene, defamatory or libellous;
        <br />
        h. infringes upon or violates any third party’s rights [including, but
        not limited to, intellectual property rights, rights of privacy
        (including without limitation unauthorized disclosure of a person’s
        name, email address, physical address or phone number) or rights of
        publicity];
        <br />
        i. promotes an illegal or unauthorized copy of another person&#39;s
        copyrighted work (see “copyright complaint” below for instructions on
        how to lodge a complaint about uploaded copyrighted material), such as
        providing pirated computer programs or links to them, providing
        information to circumvent manufacture-installed copy-protect devices, or
        providing pirated music or links to pirated music files;
        <br />
        j. contains restricted or password-only access pages, or hidden pages or
        images (those not linked to or from another accessible page);
        <br />
        k. provides material that exploits people in a sexual, violent or
        otherwise inappropriate manner or solicits personal information from
        anyone;
        <br />
        l. provides instructional information about illegal activities such as
        making or buying illegal weapons, violating someone’s privacy, or
        providing or creating computer viruses;
        <br />
        m. contains video, photographs, or images of another person (with a
        minor or an adult);
        <br />
        n. tries to gain unauthorized access or exceeds the scope of authorized
        access to the Platform or to profiles, blogs, communities, account
        information, bulletins, friend request, or other areas of the Platform
        or solicits passwords or personal identifying information for commercial
        or unlawful purposes from other users;
        <br />
        o. engages in commercial activities and/or sales without our prior
        written consent such as contests, sweepstakes, barter, advertising and
        pyramid schemes, or the buying or selling of products related to the
        Platform. Throughout these Terms of Use, Swiggy’s prior written consent
        means a communication coming from Swiggy’s Legal Department,
        specifically in response to a Parties’ request, and expressly addressing
        and allowing the activity or conduct for which a Party seeks
        authorization;
        <br />
        p. solicits gambling or engages in any gambling activity which is or
        could be construed as being illegal;
        <br />
        q. interferes with another user’s use and enjoyment of the Platform or
        any third party’s user and enjoyment of similar services;
        <br />
        r. refers to any website or URL that, in our sole discretion, contains
        material that is inappropriate for the Platform or any other website,
        contains content that would be prohibited or violates the letter or
        spirit of these Terms of Use;
        <br />
        s. harm minors in any way;
        <br />
        t. infringes any patent, trademark, copyright or other intellectual
        property rights or third party’s trade secrets or rights of publicity or
        privacy or shall not be fraudulent or involve the sale of counterfeit or
        stolen products;
        <br />
        u. violates any law for the time being in force;
        <br />
        v. deceives or misleads the addressee/users about the origin of such
        messages or communicates any information which is grossly offensive or
        menacing in nature;
        <br />
        w. impersonate another person;
        <br />
        x. contains software viruses or any other computer code, files or
        programs designed to interrupt, destroy or limit the functionality of
        any computer resource; or contains any trojan horses, worms, time bombs,
        cancelbots, easter eggs or other computer programming routines that may
        damage, detrimentally interfere with, diminish value of, surreptitiously
        intercept or expropriate any system, data or personal information;
        <br />
        y. threatens the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign states, or public order or causes
        incitement to the commission of any criminal offence or prevents
        investigation of any offence or is insulting any other nation;
        <br />
        z. directly or indirectly, offers, attempts to offer, trades or attempts
        to trade in any item, the dealing of which is prohibited or restricted
        in any manner under the provisions of any applicable law, rule,
        regulation or guideline for the time being in force; or creates
        liability for us or causes us to lose (in whole or in part) the services
        of our internet service provider or other suppliers.
        <br />
        ii. Parties shall not use any “deep-link”, “page-scrape”, “robot”,
        “spider” or other automatic device, program, algorithm or methodology,
        or any similar or equivalent manual process, to access, acquire, copy or
        monitor any portion of the Platform or any Content, or in any way
        reproduce or circumvent the navigational structure or presentation of
        the Platform or any Content, to obtain or attempt to obtain any
        materials, documents or information through any means not purposely made
        available through the Platform. We reserve our right to prohibit any
        such activity.
        <br />
        iii. Parties shall not attempt to gain unauthorized access to any
        portion or feature of the Platform, or any other systems or networks
        connected to the Platform or to any server, computer, network, or to any
        of the services offered on or through the Platform, by hacking,
        “password mining” or any other illegitimate means.
        <br />
        iv. Parties shall not probe, scan or test the vulnerability of the
        Platform or any network connected to the Platform nor breach the
        security or authentication measures on the Platform or any network
        connected to the Platform. User may not reverse look-up, trace or seek
        to trace any information on any other user of or visitor to Platform, or
        either Party, including any account on the Platform not owned by a
        Party, to its source, or exploit the Platform or any service or
        information made available or offered by or through the Platform, in any
        way where the purpose is to reveal any information, including but not
        limited to personal identification or information, other than the
        Parties own information, as provided for by the Platform.
        <br />
        v. Each Party shall not make any negative, denigrating or defamatory
        statement(s) or comment(s) about us or the brand name or domain name
        used by us including the name ‘Swiggy’, or otherwise engage in any
        conduct or action that might tarnish the image or reputation, of Swiggy
        or Merchant on platform or otherwise tarnish or dilute any Swiggy’s
        trade or service marks, trade name and/or goodwill associated with such
        trade or service marks, as may be owned or used by us. Parties agree
        that they will not take any action that imposes an unreasonable or
        disproportionately large load on the infrastructure of the Platform or
        Swiggy’s systems or networks, or any systems or networks connected to
        Swiggy.
        <br />
        vi. Parties agree not to use any device, software or routine to
        interfere or attempt to interfere with the proper working of the
        Platform or any transaction being conducted on the Platform, or with any
        other person’s use of the Platform.
        <br />
        vii. Parties may not forge headers or otherwise manipulate identifiers
        in order to disguise the origin of any message or transmittal they send
        to us on or through the Platform or any service offered on or through
        the Platform. Parties may not pretend that they are, or that such
        Parties represent, someone else, or impersonate any other individual or
        entity.
        <br />
        viii. Parties may not use the Platform or any content on the Platform
        for any purpose that is unlawful or prohibited by these Terms of Use, or
        to solicit the performance of any illegal activity or other activity
        that infringes the rights of Swiggy and/or others.
        <br />
        ix. Each Party shall at all times ensure full compliance with the
        applicable provisions, as amended from time to time, of (a) the
        Information Technology Act, 2000 and the rules thereunder; (b) all
        applicable domestic laws, rules and regulations (including the
        provisions of any applicable exchange control laws or regulations in
        force); and (c) international laws, foreign exchange laws, statutes,
        ordinances and regulations (including, but not limited to sales tax/VAT,
        income tax, octroi, service tax, central excise, custom duty, local
        levies) regarding use of our service and listing, purchase, solicitation
        of offers to purchase, and sale of products or services. Parties shall
        not engage in any transaction in an item or service, which is prohibited
        by the provisions of any applicable law including exchange control laws
        or regulations for the time being in force.
        <br />
        x. In order to allow us to use the information supplied by the Parties,
        without violating their rights or any laws, Parties agree to grant us a
        non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
        sub-licensable (through multiple tiers) right to exercise the copyright,
        publicity, database rights or any other rights that Parties have in
        their Information, in any media now known or not currently known, with
        respect to their Information. We will only use the Parties information
        in accordance with these Terms of Use and Privacy Policy applicable to
        use of the Platform.
        <br />
        xi. Please be advised that such Content posted does not necessarily
        reflect Swiggy views and such Content are provided by the manufacturers
        and/or suppliers alone. In no event shall Swiggy assume or have any
        responsibility or liability for any Content posted or for any claims,
        damages or losses resulting from use of Content and/or appearance of
        Content on the Platform. Parties hereby represent and warrant that they
        have all necessary rights in and to all Content which Parties provide
        and all information it contains and that such Content shall not infringe
        any proprietary or other rights of third parties or contain any
        libellous, tortious, or otherwise unlawful information.
        <br />
        xii. Parties correspondence or business dealings with, or participation
        in promotions of, advertisers found on or through the Platform,
        including payment or services, and any other terms, conditions,
        warranties or representations associated with such dealings, are solely
        between Parties and such advertiser. We shall not be responsible or
        liable for any loss or damage of any sort incurred as the result of any
        such dealings or as the result of the presence of such advertisers on
        the Platform.
        <br />
        xiii. It is possible that other users (including unauthorized users or
        ‘hackers’) may post or transmit offensive or obscene materials on the
        Platform and that Parties may be involuntarily exposed to such offensive
        and obscene materials. It also is possible for others to obtain personal
        information about a Party due to their use of the Platform, and that the
        recipient may use such information to harass or injure the Party. We do
        not approve of such unauthorized uses, but by using the Platform Parties
        acknowledge and agree that we are not responsible for the use of any
        personal information that Parties publicly disclose or share with others
        on the Platform. Please carefully select the type of information that a
        User publicly discloses or shares with others on the Platform.
        <br />
        xiv. Swiggy shall have all the rights to take necessary action and claim
        damages that may occur due to Party’ involvement/participation in any
        way on Parties’ own or through group/s of people, intentionally or
        unintentionally in DoS/DDoS (Distributed Denial of Services), hacking,
        pen testing attempts without our prior consent or a mutual legal
        agreement.
        <p>
          <h4>V. Account Registration or use of the Platform</h4>
        </p>
        <br />
        1. Parties may access the Platform by registering to create an account (
        <b>“Swiggy Account”</b>) and become a member (<b>“Membership”</b>).; or
        (c) Users can also register to join by logging into their account with
        certain third party social networking sites (<b>“SNS”</b>) (including,
        but not limited to, Facebook); each such account, a{" "}
        <b>“Third Party Account”</b>, via our Platform, as described below. The
        Membership is limited for the purpose and are subject to the terms, and
        strictly not transferable. As part of the functionality of the Platform
        services, Users may link their Swiggy Account with Third Party Accounts,
        by either:
        <br />
        i. providing Third Party Account login information to us through the
        Platform; or
        <br />
        ii. allowing us to access Third Party Account, as is permitted under the
        applicable terms and conditions that govern a Party’s use of each Third
        Party Account.
        <br />
        2. Parties represent that they are entitled to disclose Third Party
        Account login information to us and/or grant us access to their Third
        Party Account (including, but not limited to, for use for the purposes
        described herein), without breach by the Party of any of the terms and
        conditions that govern a Party’s use of the applicable Third Party
        Account and without obligating us to pay any fees or making us subject
        to any usage limitations imposed by such third party service providers.
        <br />
        3. By granting us access to any Third Party Accounts, Parties understand
        that we will access, make available and store (if applicable) any
        content or information that the Parties have provided to and stored in
        their Third Party Account (<b>“SNS Content”</b>) so that it is available
        on and through the Platform via each Party’s Swiggy Account.
        <br />
        4. Unless otherwise specified in these Terms of Use, all SNS Content, if
        any, will be considered to be the User’s content for all purposes of
        these Terms of Use.
        <br />
        5. Depending on the Third Party Accounts, Users choose, and subject to
        the privacy settings that they have set in such Third Party Accounts,
        personally identifiable information that they post to Third Party
        Accounts will be available on and through each User’s Swiggy Account on
        the Platform.
        <br />
        6. Please note that if a Third Party Account or associated service
        becomes unavailable or our access to such Third Party Account is
        terminated by the third party service provider, then SNS Content will no
        longer be available on and through the Platform.
        <br />
        7. We will create a Swiggy Account for each Party’s use of the Platform
        services based upon the personal information provided to us or that we
        obtain via an SNS, as described above. Parties may only have one Swiggy
        Account and not permitted to create multiple accounts. If a Party is
        found having multiple accounts, Swiggy reserves right to suspend such
        multiple account without being liable for any compensation.
        <br />
        8. Parties agree to provide accurate, current and complete information
        during the registration process and to update such information to keep
        it accurate, current and complete.
        <br />
        9. We reserve the right to suspend or terminate User’s Swiggy Account
        and the access to the Services (i) if any information provided during
        the registration process or thereafter proves to be inaccurate, not
        current or incomplete; (ii) if it is believed that a Party’s actions may
        cause legal liability for such Party, other users or us; and/or (iii) if
        a Party is found to be non- compliant with the Terms of Use.
        <br />
        10. Parties are responsible for safeguarding the password. Parties agree
        that they will not disclose their password to any third party and that
        they will take sole responsibility for any activities or actions under
        the Swiggy Account, whether or not they have authorized such activities
        or actions. Parties will immediately notify us of any unauthorized use
        of their Swiggy Account.
        <br />
        11. Products and services purchased from the Platform are intended for
        Parties’ own use only and Parties represent that the same are not for
        resale or Parties are not acting as an agent for other parties.
        <br />
        12. The Buyers shall submit and/or upload a self-attested copy of
        atleast 1 (one) of the following documents (“Business Evaluation
        Documents”) based on which Swiggy would evaluate the existence of valid
        business Buyer:
        <br />
        i. PAN Card; and
        <br />
        ii. GST Certificate; and
        <br />
        iii. Certificate of Incorporation; or
        <br />
        iv. Shops &amp; Establishments registration/Local Authority/Certificate
        of Business; or
        <br />
        v. Trade License;
        <p>
          In case Swiggy does not find the Business Evaluation Documents
          submitted by the Buyer as per its requirement, Swiggy shall not
          deliver the Product(s) to the Buyer and the Buyer’s shall be delisted
          from the Platform at sole discretion of Swiggy.
        </p>
        <p>
          <h4>VI. Bookings and Financial Terms</h4>
        </p>
        <br />
        1. The Platform allows such parties to place order bookings.
        <br />
        2. As a general rule, all orders placed on the Platform are treated as
        confirmed.
        <br />
        3. All payments for purchase by Buyer to Swiggy shall be made after
        withholding / deducting all Taxes which it is required to withhold/
        deduct under Applicable Law. In the event any such amounts are deducted
        or withheld, the Buyer shall provide Swiggy with appropriate
        certificates evidencing that such withholding / deductions have been
        made in accordance with the requirements of Applicable Law. All Taxes
        incurred by Swiggy (or any Person on behalf of Swiggy) (a) in relation
        to any promotional schemes and offers in relation to the Product(s) and
        Service(s) (which promotional schemes and offer have been agreed between
        the Parties); (b) in relation to any reimbursements or exercise of the
        Credit Notes; and (c) in relation to any return of Product(s) and
        Service(s) by Buyer to Swiggy and/or its Supplier, shall be reimbursed
        by Swiggy (or any other Person nominated by Swiggy). Apart from GST, any
        local levies and or other charges levied by any Central/state/local
        authorities wherever applicable shall be extra and Buyer shall be liable
        to discharge the same. Disclaimer: Prices on any product(s) as is
        reflected on the Platform may due to some technical issue, typographical
        error be incorrectly reflected and in such an event Swiggy may cancel
        such the Buyer’s order(s).
        <br />
        4. The manufacturers and/or supplier of the products shall be solely
        responsible for any warranty/guarantee of the products sold to the
        Buyers and in no event shall be the responsibility of Swiggy.
        <br />
        5. The Buyer may not be able to make purchases if the Buyer’s delivery
        location is outside Swiggy’s delivery service range.
        <br />
        6. Buyer understands that delivery periods quoted at the time of
        confirming the order is an approximate estimate and may vary. We will
        not be responsible for any delay in the delivery of the Buyer’s order.
        <br />
        7. Buyer’s order will be only delivered to the address designated by the
        Buyer at the time of placing the order on the Platform. We reserve the
        right to cancel the order, in our sole discretion, in the event of any
        change to the place of delivery is notified by the Merchant and the
        Buyer shall not be entitled to any refund for the same, however, can
        reschedule the delivery time. Delivery in the event of change of the
        delivery location shall be at Merchant’s sole discretion.
        <br />
        8. Services provided:
        <br />
        i. Buyer confirms that we shall not be responsible for any deficiency in
        payment of consideration payable towards the products purchased
        <br />
        ii. Buyer agrees and acknowledges that Swiggy shall not be liable in the
        event of failing to adhere to the Terms of Use.
        <br />
        9. We constantly strive to provide the Parties with accurate information
        on the Platform. However, in the event of an error, we may, in our sole
        discretion, contact the Parties with further instructions.
        <br />
        10. If the Parties use the Platform, they do the same at their own risk.
        <p>
          <h4>IX. General terms of use</h4>
        </p>
        <br />
        1. Persons who are “incompetent to contract” within the meaning of the
        Indian Contract Act, 1872 including minors, un-discharged insolvents
        etc. are not eligible to use the Platform. Only individuals who are 18
        years of age or older may use the Platform and avail Services. If any
        Party is under 18 years of age and wishes to download, install, access
        or use the Platform, such Party’s parents or legal guardian must
        acknowledge and agree to the Terms of Use and Privacy Policy. Should a
        Party’s parents or legal guardian fail to agree or acknowledge the Terms
        of Use and Swiggy policies, such Party shall immediately discontinue its
        use. Swiggy reserves the right to terminate the Membership and / or deny
        access to the Platform if it is brought Swiggy’s notice that the Party
        is under the age of 18 years.
        <br />
        2. If a Party chooses to use the Platform, it shall be the Party’s
        responsibility to treat its user identification code, password and any
        other piece of information that we may provide, as part of our security
        procedures, as confidential and not disclose the same to any person or
        entity other than us. We shall at times and at our sole discretion
        reserve the right to disable any user identification code or password if
        the Party has failed to comply with any of the provisions of these Terms
        of Use.
        <br />
        3. As we are providing services in the select cities in India, we have
        complied with applicable laws of India in making the Platform and its
        content available to the Parties. In the event the Platform is accessed
        from outside India , it shall be entirely at such Parties’ risk. We make
        no representation that the Platform and its contents are available or
        otherwise suitable for use outside select cities. If a Party chooses to
        access or use the Platform from or in locations outside select cities,
        such Party may do so on its own risk and shall be responsible for the
        consequences and ensuring compliance of applicable laws, regulations,
        byelaws, licenses, registrations, permits, authorisations, rules and
        guidelines.
        <br />
        4. Each Party shall at all times be responsible for the use of the
        Services through a Party’s computer or mobile device and for bringing
        these Terms of Use and Swiggy policies to the attention of all such
        persons accessing the Platform on a Party’s computer or mobile device.
        <br />
        5. Parties understand and agree that the use of the Services does not
        include the provision of a computer or mobile device or other necessary
        equipment to access it. Parties also understand and acknowledge that the
        use of the Platform requires internet connectivity and telecommunication
        links. Parties shall bear the costs incurred to access and use the
        Platform and avail the Services, and we shall not, under any
        circumstances whatsoever, be responsible or liable for such costs.
        <br />
        6. Parties agree and grant permission to Swiggy to receive promotional
        SMS and emails from Swiggy or allied partners. In case Parties wish to
        opt out of receiving promotional SMS or email please send a mail to{" "}
        {"xyz@swiggy.in"}.
        <br />
        7. By using the Platform, Parties represent and warrant that:
        <br />
        i. All registration information submitted is truthful, lawful and
        accurate and that Parties agree to maintain the accuracy of such
        information.
        <br />
        ii. Parties use of the Platform shall be solely for their own use and
        each Party shall not authorize any other to use their account, including
        a Party’s profile or email address and that each Party is are solely
        responsible for all content published or displayed through the account,
        including any email messages, and a Party’s interactions with other
        users and each Party shall abide by all applicable local, state,
        national and foreign laws, treaties and regulations, including those
        related to data privacy, international communications and the
        transmission of technical or personal data.
        <br />
        iii. Each Party will not submit, post, upload, distribute, or otherwise
        make available or transmit any content that: (a) is defamatory, abusive,
        harassing, insulting, threatening, or that could be deemed to be
        stalking or constitute an invasion of a right of privacy of another
        person; (b) is bigoted, hateful, or racially or otherwise offensive; (c)
        is violent, vulgar, obscene, pornographic or otherwise sexually
        explicit; (d) is illegal or encourages or advocates illegal activity or
        the discussion of illegal activities with the intent to commit them.
        <br />
        iv. All necessary licenses, consents, permissions and rights are owned
        by the Merchant and there is no need for any payment or permission or
        authorization required from any other party or entity to use, distribute
        or otherwise exploit in all manners permitted by these Terms of Use and
        Privacy Policy, all trademarks, copyrights, patents, trade secrets,
        privacy and publicity rights and / or other proprietary rights contained
        in any content that the Merchant submits, posts, uploads, distributes or
        otherwise transmits or makes available.
        <br />
        v. Each Party shall not use the Platform in any way that is unlawful, or
        harms us or any other person or entity, as determined in our sole
        discretion.
        <br />
        vi. Each Party shall not post, submit, upload, distribute, or otherwise
        transmit or make available any software or other computer files that
        contain a virus or other harmful component, or otherwise impair or
        damage the Platform or any connected network, or otherwise interfere
        with any person or entity’s use or enjoyment of the Platform.
        <br />
        vii. Each Party shall not use another person’s username, password or
        other account information, or another person’s name, likeness, voice,
        image or photograph or impersonate any person or entity or misrepresent
        a Party’s identity or affiliation with any person or entity.
        <br />
        viii. Each Party will not engage in any form of antisocial, disrupting,
        or destructive acts, including “flaming,” “spamming,” “flooding,”
        “trolling,” and “griefing” as those terms are commonly understood and
        used on the Internet.
        <br />
        ix. Each Party will not post or contribute any information or data that
        may be obscene, indecent, pornographic, vulgar, profane, racist, sexist,
        discriminatory, offensive, derogatory, harmful, harassing, threatening,
        embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue
        or political or contrary to our interest.
        <br />
        x. Each Party shall not access the Platform without authority or use the
        Platform in a manner that damages, interferes or disrupts:
        <br />
        a. any part of the Platform or the Platform software; or
        <br />
        b. any equipment or any network on which the Platform is stored or any
        equipment of any third party.
        <br />
        8. Buyer shall release and fully indemnify Swiggy and/or any of its
        officers and representatives from any cost, damage, liability or other
        consequence of any of the actions by use of the Platform and
        specifically waive any claims such Party may have in this behalf under
        any applicable laws of India. Notwithstanding its reasonable efforts in
        that behalf, Swiggy cannot take responsibility or control the
        information provided by other Users which is made available on the
        Platform. A Party may find other User’s information to be offensive,
        harmful, inconsistent, inaccurate, or deceptive. Please use caution and
        practice safe trading when using the Platform.
        <p>
          <h4>X. Access to the Platform, Accuracy and security</h4>
        </p>
        <br />
        1. We do not represent that access to the Platform will be
        uninterrupted, timely, error free, free of viruses or other harmful
        components or that such defects will be corrected.
        <br />
        2. We do not warrant that the Platform will be compatible with all
        hardware and software which
        <br />a Party may use. We shall not be liable for damage to, or viruses
        or other code that may affect, any equipment (including but not limited
        to a mobile device), software, data or other property as a result of
        download, installation, access to or use of the Platform or obtaining
        any material from, or as a result of using, the Platform. We shall also
        not be liable for the actions of third parties.
        <br />
        3. We do not represent or warrant that the information available on the
        Platform will be correct, accurate or otherwise reliable.
        <br />
        4. We reserve the right to suspend or withdraw access to the Platform to
        a Party, individually, or to all users temporarily or permanently at any
        time without notice. We may any time at our sole discretion reinstate
        suspended users. A suspended User may not register or attempt to
        register with us or use the Platform in any manner whatsoever until such
        time that such user is reinstated by us.
        <p>
          <h4>
            XI. Relationship with operators if the Platform is accessed on
            mobile devices
          </h4>
        </p>
        <br />
        1. In the event the Platform is accessed on a mobile device, it is not
        associated, affiliated, sponsored, endorsed or in any way linked to any
        platform operator, including, without limitation, Apple, Google, Android
        or RIM Blackberry (each being an <b>“Operator”</b>).
        <br />
        2. Any download, installation, access to or use of the Platform is also
        bound by the terms and conditions of the Operator.
        <br />
        3. Parties and Swiggy acknowledge that these Terms of Use are concluded
        between the Parties and Swiggy only, and not with an Operator, and we,
        not those Operators, are solely responsible for the Platform and the
        content thereof to the extent specified in these Terms of Use.
        <br />
        4. The license granted to each Party for the Platform is limited to a
        non-transferable license to use the Platform on a mobile device that a
        Party owns or controls and as permitted by these Terms of Use.
        <br />
        5. We are solely responsible for providing any maintenance and support
        services with respect to the Platform as required under applicable law.
        Each Party and Swiggy acknowledges that an Operator has no obligation
        whatsoever to furnish any maintenance and support services with respect
        to the Platform.
        <br />
        6. Parties and Swiggy acknowledge that Swiggy, not the relevant
        Operator, is responsible for addressing any claims of each Party or any
        third party relating to the Platform or a Party’s possession and/or use
        of the Platform, including, but not limited to: (i) any claim that the
        Platform fails to conform to any applicable legal or regulatory
        requirement; and (ii) claims arising under consumer protection or
        similar legislation.
        <br />
        7. Parties and Swiggy acknowledge that, in the event of any third party
        claim that the Platform or a Party’s possession and use of the Platform
        infringes that third party’s intellectual property rights, Swiggy, not
        the relevant Operator, will be solely responsible for the investigation,
        defence, settlement and discharge of any such intellectual property
        infringement claim.
        <br />
        8. Each Party must comply with any applicable third party terms of
        agreement when using the Platform (e.g. User must ensure that the use of
        the Platform is not in violation of their mobile device agreement or any
        wireless data service agreement).
        <br />
        9. Parties and Swiggy acknowledge and agree that the relevant Operator,
        and that Operator’s subsidiaries, are third party beneficiaries of these
        Terms of Use, and that, upon Parties’ acceptance of these Terms of Use,
        that Operator will have the right (and will be deemed to have accepted
        the right) to enforce these Terms of Use against such Party as a third
        party beneficiary thereof.
        <p>
          <h4>XII. Disclaimers</h4>
        </p>
        <br />
        1. THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND
        FEATURES MAY NOT BE FULLY OPERATIONAL.
        <br />
        2. DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF
        INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION
        OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR
        INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS
        IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT
        THE INFORMATION POSTED IS CORRECT IN EVERY CASE.
        <br />
        3. WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE
        OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
        <br />
        4. PARTIES ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE
        PLATFORM. PARTIES MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF
        THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE
        PROVISION OF CERTAIN SERVICES TO THE PARTIES. HOWEVER, PARTIES
        ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION
        OR WARRANTY REGARDING ANY THIRD PARTY’S SERVICES NOR WILL WE BE LIABLE
        TO EACH PARTY OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING
        FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED
        TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT
        EXPERIENCED BY SUCH PARTY OR ANY THIRD PARTY. EACH PARTY HEREBY
        DISCLAIMS AND WAIVES ANY RIGHTS AND CLAIMS IT MAY HAVE AGAINST SWIGGY
        WITH RESPECT TO THIRD PARTY’S / MERCHANTS SERVICES.
        <br />
        5. SWIGGY DISCLAIMS ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF
        THE FOOD SAFETY AND STANDARDS ACT, 2006 AND PLASTIC WASTE MANAGEMENT
        RULES, 2016 FOR THE TIME BEING IN FORCE AND APPLICABLE RULES AND
        REGULATIONS MADE THEREUNDER AND SUCH LIABILITY SHALL BE ATTRIBUTABLE TO
        THE MANUFACTURER AND/OR SUPPLIER.
        <br />
        6. WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE
        ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION
        CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE
        UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES
        WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL
        QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN.
        FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED
        HEREIN. IN NO EVENT SHALL WE BE LIABLE TO EITHER PARTY OR ANY THIRD
        PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH
        INFORMATION.
        <br />
        7. THE INFORMATION PROVIDED HEREUNDER IS PROVIDED “AS IS”. WE AND / OR
        OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE
        TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS
        OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION
        OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY
        USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE
        JUDGEMENT OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT,
        MODIFY OR ALTER SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY OTHER
        SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS,
        BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM
        ANY KIND OF PROFESSIONAL LIABILITY.
        <br />
        8. WE SHALL NOT BE LIABLE TO THE PARTIES OR ANYONE ELSE FOR ANY LOSSES
        OR INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE
        PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR
        AGENTS BE LIABLE TO EACH PARTY OR ANY THIRD PARTY FOR ANY DECISION MADE
        OR ACTION TAKEN BY A PARTY’S RELIANCE ON THE CONTENT CONTAINED HEREIN.
        <br />
        9. IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
        LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
        EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH,
        AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS
        INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY THIRD PARTY
        OR MERCHANT ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY,
        CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        <p>
          <h4>XIII. Intellectual Property</h4>
        </p>
        <br />
        1. We are either the owner of intellectual property rights or have the
        non-exclusive, worldwide, perpetual, irrevocable, royalty free,
        sub-licensable (through multiple tiers) right to exercise the
        intellectual property, in the Platform, and in the material published on
        it.
        <br />
        2. Users may print off one copy, and may download extracts, of any
        page(s) from the Platform for personal reference and may draw the
        attention of others within the User’s organisation to material available
        on the Platform.
        <br />
        3. Parties must not modify the paper or digital copies of any materials
        printed off or downloaded in any way, and must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
        <br />
        4. Parties must not use any part of the materials on the Platform for
        commercial purposes without obtaining a licence to do so from us or our
        licensors.
        <br />
        5. If a Party prints, copies or downloads any part of the Platform in
        breach of these Terms of Use, a Party’s right to use the Platform will
        cease immediately and each Party must, at our option, return or destroy
        any copies of the materials they have made.
        <p>
          <h4>XIV. Treatment of information provided by Parties</h4>
        </p>
        We process information provided by the Parties to us in accordance with
        our Privacy Policy.
        <p>
          <h4>XV. Third Party Content</h4>
        </p>
        <br />
        1. We cannot and will not assure that other users are or will be
        complying with the foregoing rules or any other provisions of these
        Terms of Use, and, as between the Parties and us, each Party hereby
        assumes all risk of harm or injury resulting from any such lack of
        compliance.
        <br />
        2. Parties acknowledge that while accessing a link that leaves the
        Platform, the site a Party will enter into is not controlled by us and
        different terms of use and privacy policy may apply. By assessing links
        to other sites, Parties acknowledge that we are not responsible for
        those sites. We reserve the right to disable links to and / or from
        third-party sites to the Platform, although we are under no obligation
        to do so.
        <p>
          <h4>XVI. Severability</h4>
        </p>
        If any of these Terms of Use should be determined to be illegal, invalid
        or otherwise unenforceable by reason of the laws of any state or country
        in which these Terms of Use are intended to be effective, then to the
        extent and within the jurisdiction where that term is illegal, invalid
        or unenforceable, it shall be severed and deleted and the remaining
        Terms of Use shall survive, remain in full force and effect and continue
        to be binding and enforceable.
        <p>
          <h4>XVII. Non-assignment</h4>
        </p>
        Each Party shall not assign or transfer or purport to assign or transfer
        the contract between a Party and us to any other person.
        <p>
          <h4>XVIII. Governing law and dispute resolution</h4>
        </p>
        These Terms of Use are governed by the laws of India. Any action, suit,
        or other legal proceeding, which is commenced to resolve any matter
        arising under or relating to this Platform, shall be subject to the
        jurisdiction of the courts at Bangalore, India.
        <p>
          <h4>XIX. IP Notice and Take Down Policy</h4>
        </p>
        <br />
        1. Swiggy has put in place IP Notice and Take Down Policy (
        <b>“Take Down Policy”</b>) so that intellectual property owners could
        easily report listings that infringe their right to ensure that
        infringing products are removed from the site, as they erode Buyer and
        good Seller trust.
        <br />
        2. Only the intellectual property rights owner can report potentially
        infringing products or listings through Take Down Policy by way of
        Notice of infringement in the specified format.
        <br />
        If a Party is not the intellectual property rights owner, it can still
        help by getting in touch with the rights owner and encouraging them to
        contact us.
        <br />
        <i>
          (Note: Swiggy does not and cannot verify that the manufacturers and/or
          suppliers have the right or ability to sell or distribute their listed
          products. However, Swiggy is committed ensure that item listings do
          not infringe upon intellectual property rights of third parties once
          an authorized representative of the rights owner properly reports them
          to Swiggy.)
        </i>
        <p>
          <h4>XX. Contact Us</h4>
        </p>
        Please contact us for any questions or comments (including all inquiries
        unrelated to copyright infringement) regarding this Platform. Grievance
        officer In accordance with Information Technology Act, 2000 and rules
        made there under, the name and contact details of the Grievance Officer
        are provided below:
        <br />
        Mr. Suryansh Kumar
        <br />
        Bundl Technologies Private Limited
        <br />
        Reg Office: 3rd Floor (Internally designated as 4th Floor)
        <br />
        Maruthi Chambers, Survey No 17/9B,
        <br />
        Roopena Agrahara Bangalore- 560068, India
        <br />
        Phone: {3456789}
        <br />
        Email: {"xyz@swiggy.in"}
        <br />
        Time: Mon - Sat (9:00 - 18:00)
        <p>
          <h4>XXI. ORDER AND DELIVERY PROCESS</h4>
        </p>
        <br />
        1. The Buyer shall buy, the desired quantities of Products at the price
        to be paid for the Product listed by Swiggy for the Buyer at the
        relevant Purchase Price as listed on the Platform. Swiggy shall issue
        the Order Reference to an order by the Buyer, each delivery of Product
        by Swiggy or by the manufacturer and/or supplier to the Buyers on the
        Delivery Date shall be accompanied by a Delivery Note issued by the
        Swiggy and/or its supplier, as the case may be. The Delivery Note will
        specify the Order Reference Number, the date of the Purchase, terms of
        return and replacement of Product, the type and quantity of Products and
        Services delivered.
        <br />
        2. Upon delivery of each order of Product at the Delivery Location ( the
        location specified by Buyer for delivery of Products), the sale and
        delivery of the Products shall be effective on confirmation by Buyer,
        which confirmation shall be made by an authorized representative of
        Buyer <br />
        (a) stamping a copy of Delivery Note; <br />
        (b) acknowledging receipt of the Products(s) by countersigning the
        Delivery Note, and returning such stamped and countersigned copy to the
        Swiggy and/or its supplier; and <br />
        (c) as may be in electronic form directed by Swiggy time to time to the
        Buyer (collectively “Acknowledgement Actions”).
        <br />
        3. The Order Reference shall be binding on the Buyer unless it is
        revoked by Swiggy at its sole discretion. Notwithstanding anything
        contained in this MOU, Swiggy shall be entitled to amend or cancel Order
        Reference at its sole discretion at any time prior to the delivery of
        the Product by Swiggy to the Buyer at the Delivery Location. Each Party
        shall use the Order Reference to identify each order, and the relevant
        Order Reference shall be used in all subsequent correspondence relating
        to the Products supplied pursuant to such Delivery Note being issued by
        Swiggy and/or its supplier.
        <br />
        4. Upon delivery of each order of Product at the Delivery Location and
        on confirmation by Buyer by Acknowledgement Actions, Swiggy shall issue
        Invoice” to Buyer, which shall inter alia include the name of the
        relevant Product being sold to the Buyer, price at which the Product or
        Services is being sold to Buyer by Swiggy, total quantity of the Product
        being sold to the Buyer, credit terms being offered by Swiggy to the
        Buyer.
        <p>
          <h4>XXII. PAYMENT PROCESS:</h4>
        </p>
        Subject to any discounts or rebates agreed between the Swiggy and
        Buyers, the price to be paid by Buyer to Swiggy for the Product
        purchased by Buyer shall be the Consideration (Consideration shall mean
        the amount which is computed by multiplying the number of Purchased
        Products with the Purchase Price. For the avoidance of doubt, it is
        clarified that in computing the number of Purchased Products to
        determine the Consideration, Purchased Products shall include only those
        Products which Swiggy (Invoices issued by Swiggy to Buyer and any return
        by Buyer to Swiggy and/or it seller or its Service Provider, the
        statement shall mutually finalized with conclusive proof).
        <br />
        1) In case of pre-payment before delivery:
        <br />
        i. Buyer shall make the payment upon receiving the sale order
        confirmation with payment link from us.
        <br />
        ii. Upon confirmation of payment, we shall confirm the same to the buyer
        for order processing.
        <br />
        2) In case of goods being purchased on credit
        <br />
        i. Swiggy shall check the order against credit limit:
        <br />
        a. IF the amount is within the stipulated credit limit or credit period,
        the order shall be confirmed to the buyer on mail.
        <br />
        b. IF the amount is beyond the stipulated credit limit or credit period,
        a pre-payment link shall be shared with the partner. Upon receiving
        payment confirmation, we shall confirm the same to the buyer for order
        processing.
        <br />
        3) Payment in case of credit availed: Swiggy shall share a statement of
        account either 1 day before the expiry of credit period or upon
        exhaustion of credit limit, whichever is earlier. The Buyer can raise a
        dispute within 24 hours of receiving the statement of account, upon no
        enquiry, subsequently the account statement would be deemed correct. IF
        no dispute is raised then the payment shall be made by the Buyer within
        the same 24 hours, failing which Swiggy shall recover the same as per
        the Letter of Acceptance signed by them. Buyer undertake to promptly pay
        for the Products purchased through the website and in the event within
        the agreed timelines and if Buyer fail to pay as per agreed timelines
        then in such event, Swiggy shall be authorised to deduct outstanding
        amount(s) from the collections made by Swiggy on behalf of the Buyer
        under terms of marketplace MOU/LOU executed between Swiggy and Buyer.
        Swiggy shall be entitled to make such deductions from the settlement of
        collections made over weekly Settlement Period, till such outstanding
        amount(s) are fully paid.
        <p>
          <h4>XXIII. RETURN, REPLACEMENT, EXCHANGE AND CANCELLATION PROCESS</h4>
        </p>
        Buyer shall be obligated to follow the return, exchange and cancellation
        policy for the Products, as may be normally offered by it to the Buyers
        or as may be offered by Swiggy (as per Swiggy’s standards), whichever is
        more advantageous to the Buyers. Any issues related to return, exchange
        and cancellation shall be raised by the Buyer as per the timelines and
        instruction of related purchase of Product mentioned below. Any delay by
        the Buyer for return, exchange and cancellation on the give timelines
        and instruction then Swiggy shall not be liable for any return, exchange
        and cancellation of Products and either liable for any damages, cost for
        penalty to the Buyer.
        <br />
        i. <b>Cancellation policy</b>
        <br />
        Any cancellation can be made by 10 PM for Mumbai and 7 PM for Bangalore
        everyday for next day delivery. There is no cancellation allowed for the
        products after 10 PM/7PM respectively.
        <br />
        <b>ii. Replacement of Raw Materials</b>
        <br />
        Buyer can request for replacement of raw materials which is fresh at the
        Point of delivery. Fresh includes all perishable items - dairy, milk,
        poultry, meat, vegetables, leafy vegetables For the replacement of non
        perishable items - groceries (rice, atta, pickle etc) and Oil, the buyer
        can request for replacement within 2 days time from the date of
        delivery. Please note: The item should be returned in original state
        (packets should be opened from top and tested) and quantity should be
        retained.
      </p>
    </div>
  );
}

export default Terms;
