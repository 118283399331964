import React, { useEffect } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";

import Home from "./screens/Home/Home";
import Terms from "./screens/TermsAndCondition/Terms";

import "./App.css";

function App() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const link = searchParams.get("link");
    if (link) {
      window.location.replace(link);
    }
  }, [searchParams]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-condition" element={<Terms />} />
      </Routes>
    </div>
  );
}

export default App;
