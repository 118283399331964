import React from "react";

import Preloader from "../../components/Preloader/Preloader";
import Timer from "../../components/Countdown/Timer";

import "./Home.css";

function Home() {
  return (
    <div className="body-home">
      <div className="home-container">
        <div className="container">
          <h1>
            Website
            <br />
            Coming Soon
          </h1>
          <Timer />
          <Preloader />
        </div>
      </div>
    </div>
  );
}

export default Home;
